export default [
    {
        path: '/components', 
        name: 'components',
        component: () => import('./components.js')
    },
    {
        path: '/postmedia',
        name: 'postmedia',
        component: () => import('./postmedia')
    },
    {
        path: '/login', 
        name: 'login',
        component: () => import('./login.js')
    },
    {
        path: '/provider/signup', 
        name: 'provider-signup',
        component: () => import('./provider/signup.js')
    },
    {
        path: '/provider/login', 
        name: 'provider-login',
        redirect: '/login',
        //component: () => import('./provider/login.js')
    },
    {
        path: '/provider/password', 
        name: 'provider-password',
        component: () => import('./provider/password.js')
    },
    {
        path: '/wechat', 
        name: 'wechat',
        component: () => import('./wechat.js'),
    },
    {
        path: '/expired', 
        name: 'expired',
        component: () => import('./expired.js')
    },
    {
        path: '/tip/:type', 
        name: 'tip',
        component: () => import('./tip.js')
    },
    {
        path: '/convert', 
        name: 'convert',
        component: () => import('./convert.js')
    },
    {
        path: '/help', 
        redirect: '/help/login'
    },
    {
        path: '/help/:id?', 
        name: 'help',
        component: () => import('./help.js')
    },
    {
        path: '/', 
        name: 'home',
        component: () => import('./home.js'),
    },
    {
        path: '/pdf/:id',
        name: 'pdf',
        component: () => import('./pdf.js'),
    },
    {
        path: '/data-report',
        name: 'data-report',
        component: () => import('./cica-data/data-report.js'),
    },
    {
        path: '/data-report/:id',
        name: 'data-report-detail',
        component: () => import('./cica-data/data-report-detail.js'),
    },
    {
        path: '/cica-data/data-item-:termId(\\d+)',
        name: 'cica-data-item',
        component: () => import('./cica-data/data-item.js'),
    },
    {
        path: '/cica-data/data-item-:termId(\\d+)/:id',
        name: 'cica-data-item-detail',
        component: () => import('./cica-data/data-item-detail.js'),
    },
    {
        path: '/industry-research-:termId(\\d+)?|:contentId(\\d+)?',
        name: 'industry-research-list',
        component: () => import('./industry-research/list.js'),
    },
    {
        path: '/industry-research-detail-:contentId(\\d+)',
        name: 'industry-research-detail',
        component: () => import('./industry-research/detail'),
    },
    {
        path: '/news-:termId(\\d+)',
        name: 'news',
        component: () => import('./news/list.js'),
    },
    {
        path: '/news-:termId(\\d+)/clue-form',
        name: 'clue-form',
        component: () => import('./news/clue-form.js'),
    },
    {
        path: '/news-:termId(\\d+)/:articleId(\\d+)',
        name: 'news-detail',
        component: () => import('./news/detail.js'),
    },
    {
        path: '/my',
        name: 'my',
        component: () => import('./my/index.js'),
        redirect: '/my/clue',
        children: [
            {
                name: 'my-clue',
                path: 'clue',
                component: () => import('./my/clue.js'),
            },
        ],
    },
    {
        path: '/f2e-grid3',
        name: 'grid3',
        component: () => import('./grid3.js'),
    },
    {
        path: '/404',
        name: 'not-found',
        component: () => import('./not-found.js'),
    },
    {
        path: '/:nav/:menu?', 
        name: 'index',
        component: () => import('./index.js'),
    },
];
