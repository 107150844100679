import template from '../templates/footer.html';

export default {
    name: 'x-footer',
    template,
    data() {
        return {
            thisYear: (new Date).getFullYear()
        };
    }
};
